import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import { Overflow } from 'client/components/shared/styled'
import { Wrap, Tab, TabContent, TabLabel, Tabs } from './styled'

const Faq = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Overflow>
                <Fade triggerOnce direction="up" duration={1500}>
                    <h2>{t('faq-title')}</h2>
                </Fade>
            </Overflow>

            <Tabs>
                {Array.from(Array(5)).map((i, idx) => (
                    <Fade
                        key={idx}
                        triggerOnce
                        direction="up"
                        duration={1500}
                        delay={idx * 120}
                    >
                        <Tab>
                            <input
                                type="checkbox"
                                id={`faq-${idx}`}
                                defaultChecked={idx === 0}
                            />
                            <TabLabel htmlFor={`faq-${idx}`}>
                                <h3>{t(`faq-item${idx}-title`)}</h3>
                            </TabLabel>
                            <TabContent>
                                <p>{t(`faq-item${idx}-text`)}</p>
                            </TabContent>
                        </Tab>
                    </Fade>
                ))}
            </Tabs>
        </Wrap>
    )
}

export default Faq
