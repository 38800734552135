import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <path
                fill="#000"
                d="M68.802 7.21a3.95 3.95 0 00-2.836 1.192l.025-.027L41.136 31.62 27.033 44.808h-.002a.8.8 0 00-.483.31l-14.565 4.857a.8.8 0 00-.48.438s-3.927 9.102 3.194 16.403c7.119 7.303 15.981 3.207 15.981 3.207a.801.801 0 00.42-.48l4.815-15.19L49 39.628l22.638-25.467c1.55-1.591 1.55-4.168 0-5.76h-.002a3.947 3.947 0 00-2.834-1.192zm0 1.58c.608 0 1.215.244 1.689.729a2.543 2.543 0 01-.025 3.551L48.217 38.1l-5.804-5.483L67.084 9.544a2.359 2.359 0 011.717-.753zM41.826 33.165l5.86 5.535-12.356 13.9-6.903-6.904 13.4-12.53zm-14.734 13.46l7.285 7.284L29.69 68.69c-.475.212-3.031 1.264-6.28.889l1.942-4.625a.4.4 0 10-.737-.31l-2.024 4.818c-2.133-.401-4.5-1.457-6.75-3.764a15.649 15.649 0 01-.637-.699l2.487-2.306a.399.399 0 00-.264-.697.4.4 0 00-.28.11l-2.448 2.27c-1.979-2.56-2.643-5.194-2.737-7.427l6.973-2.522a.4.4 0 10-.272-.753l-6.713 2.428c.05-2.488.747-4.335.912-4.734l14.23-4.745zM22.808 57.01a.4.4 0 00-.28.11l-2.358 2.185a.401.401 0 00.257.694.4.4 0 00.287-.106l2.358-2.186a.4.4 0 00-.264-.697z"
            ></path>
        </svg>
    )
}

export default Icon
