import styled from 'styled-components'
import { fontFamily, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    padding: 80px 0;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        padding: 100px 0;
    }
    ${media.desktop} {
        padding: 160px 0 180px 0;
    }

    h2 {
        text-align: center;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.xl} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Subtitle = styled.p`
    padding: 20px 0 40px 0;
    text-align: center;
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    &:nth-child(1) {
        padding-bottom: 35px;
    }
    &:nth-child(2) {
    }

    img {
        max-width: 100%;
        height: auto;
        border-radius: 30px;
    }

    ${media.xl} {
        width: 50%;
        align-items: flex-start;
        justify-content: center;

        &:nth-child(1) {
            align-items: flex-end;
            padding-right: 35px;
            padding-bottom: 0px;
        }
        &:nth-child(2) {
            align-items: flex-start;
            padding-left: 35px;
        }
    }

    p {
        max-width: 540px;

        ${media.xl} {
            max-width: 456px;
        }
    }

    button {
        max-width: 200px;
    }
`

export const Ul = styled.ul`
    margin: 34px 0;
    padding-left: 24px;

    ${media.xl} {
        margin: 50px 0;
    }
`

export const Li = styled.li`
    font-size: 14px;
    font-family: ${fontFamily.bold};

    ${media.tablet} {
        font-size: 18px;
        line-height: 32px;
    }
`
