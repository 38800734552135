import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
            {...props}
        >
            <circle cx="30" cy="30" r="30" fill="#fff" opacity="0.12"></circle>
            <circle cx="30" cy="30" r="22" fill="#fff"></circle>
            <path
                fill="#000"
                d="M20.048 31.409V28.76c0-.401.126-.727.378-.98.267-.266.593-.4.979-.4h16.778c.4 0 .727.134.98.4.266.253.4.579.4.98v2.648c0 .4-.134.727-.4.979-.253.252-.58.378-.98.378H21.405c-.4 0-.727-.126-.98-.378-.251-.252-.377-.579-.377-.98z"
            ></path>
        </svg>
    )
}

export default Icon
