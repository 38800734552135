import React from 'react'
import { useTranslation } from 'react-i18next'

import Head from '../components/shared/Head'
import Hero from '../sections/Hero'
import About from '../sections/About'
import Collection from '../sections/Collection'
import Features from '../sections/Features'
import Discord from '../sections/Discord'
import Usp from '../sections/Usp'
import App from '../sections/App'
import Roadmap from '../sections/Roadmap'
import Team from '../sections/Team'
import Faq from '../sections/Faq'

const Home = () => {
    const [t] = useTranslation()

    return (
        <>
            <Head />
            <Hero />
            <About />
            <Collection />
            <Features />
            <Discord />
            <Usp />
            <App />
            <Roadmap />
            <Team />
            <Faq />
        </>
    )
}

export default Home
