import { colors, fontFamily, media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    padding: 80px 16px;
    color: ${colors.white};
    background-color: ${colors.black};

    ${media.tablet} {
        padding: 100px 16px;
    }

    h2 {
        text-align: center;
        max-width: 490px;
        margin: 0 auto;
    }
`

export const Subtitle = styled.p`
    text-align: center;
    padding: 20px 0 40px 0;
`

export const Inner = styled.div`
    max-width: 880px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.desktop} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
        max-width: 300px;
    }

    &:nth-child(1) {
        img {
            height: auto;
            width: 100%;
            max-width: 240px;

            ${media.tablet} {
                max-width: 300px;
            }
            ${media.desktop} {
                max-width: 417px;
            }
        }

        margin-bottom: 40px;
    }
    &:nth-child(2) {
    }

    ${media.desktop} {
        width: 50%;
        align-items: flex-start;

        p {
            max-width: initial;
        }

        &:nth-child(1) {
            margin-bottom: 0px;
            padding-right: 24px;
        }
        &:nth-child(2) {
            padding-left: 24px;
        }
    }
`

export const Ul = styled.ul`
    padding-left: 24px;
    margin: 26px 0 42px 0;
`

export const Li = styled.li`
    font-size: 14px;
    font-family: ${fontFamily.bold};

    ${media.tablet} {
        font-size: 18px;
        line-height: 32px;
    }
`

export const MembershipWrap = styled.div`
    background-color: ${colors.white};
    border-radius: 30px;
    padding: 24px;
    max-width: 300px;
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    color: ${colors.black};

    ${media.tablet} {
        max-width: 340px;
    }

    h4 {
        padding: 14px 0;
    }
    p {
        font-size: 14px;
        line-height: 18px;
    }
`
