import { media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 60px;
    flex-direction: column;

    ${media.tablet} {
        padding-bottom: 110px;
    }
`

export const Subtitle = styled.p`
    padding: 18px 0 40px 0;
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const Col = styled.div`
    display: flex;
    border-radius: 30px;
    flex-direction: column;
    background-color: #f9f9f9;
    padding: 30px 30px 30px 22px;
    margin-bottom: 30px;

    width: 100%;

    ${media.custom(660)} {
        width: calc(50% - 14px);
        padding: 60px 46px 30px 22px;
    }

    ${media.xl} {
        width: calc(33% - 14px);
    }

    svg {
        margin-bottom: 26px;
    }

    h3 {
        padding-bottom: 14px;
    }
`
