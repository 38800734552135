import styled from 'styled-components'
import { media } from '@variables'

export const Wrapper = styled.div`
    width: 100%;

    @media (max-width: 479px) {
        .react-parallax-bgimage {
            left: -10% !important;
        }
    }
`

export const Wrap = styled.div`
    padding: 80px 0 440px 0;
    max-width: 920px;
    margin: 0 auto;

    ${media.tablet} {
        padding: 124px 0 135px 0;
    }

    h2 {
        padding: 100px 0 50px 0;

        ${media.tablet} {
            padding: 140px 0 50px 0;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
    }
`

export const Col = styled.div`
    width: 100%;

    &:nth-child(1) {
        margin-bottom: 40px;
    }

    ${media.tablet} {
        width: 50%;

        &:nth-child(1) {
            margin-bottom: 0px;
            padding-right: 25px;
        }
        &:nth-child(2) {
            margin-bottom: 0px;
            padding-left: 25px;
        }
    }
`

export const Divider = styled.div`
    width: 100%;
    height: 300px;

    ${media.tablet} {
        height: 350px;
    }
    ${media.desktop} {
        height: 450px;
    }
    ${media.xl} {
        height: 550px;
    }
`
