import React from 'react'
import { Routes } from '@variables'
import { Row, Wrap, Col } from './styled'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Icon0 from 'client/components/SVG/Roadmap/0'
import Icon1 from 'client/components/SVG/Roadmap/1'
import Icon2 from 'client/components/SVG/Roadmap/2'
import Icon3 from 'client/components/SVG/Roadmap/3'
import Icon4 from 'client/components/SVG/Roadmap/4'
import { Overflow } from 'client/components/shared/styled'

const Roadmap = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Overflow>
                <Fade triggerOnce direction="up" duration={1500}>
                    <h2 id={Routes.ROADMAP_HASH}>{t('roadmap-title')}</h2>
                </Fade>
            </Overflow>
            <Row>
                <Icon0 />
                <Col>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>0 %</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('roadmap-item0')}</p>
                    </Fade>
                </Col>
            </Row>
            <Row>
                <Icon1 />
                <Col>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>30 %</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('roadmap-item1')}</p>
                    </Fade>
                </Col>
            </Row>
            <Row>
                <Icon2 />
                <Col>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>60 %</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('roadmap-item2')}</p>
                    </Fade>
                </Col>
            </Row>
            <Row>
                <Icon3 />
                <Col>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>80 %</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('roadmap-item3')}</p>
                    </Fade>
                    <Fade triggerOnce duration={2500}>
                        <p style={{ textDecoration: 'underline' }}>
                            {t('roadmap-item3-0')}
                        </p>
                    </Fade>
                </Col>
            </Row>
            <Row>
                <Icon4 />
                <Col>
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h3>100 %</h3>
                        </Fade>
                    </Overflow>
                    <Fade triggerOnce duration={2500}>
                        <p>{t('roadmap-item4')}</p>
                    </Fade>
                </Col>
            </Row>
        </Wrap>
    )
}

export default Roadmap
