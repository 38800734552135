import styled from 'styled-components'

import { media } from '@variables'

export const Wrap = styled.div`
    width: calc(100% - 32px);
    color: white;
    background: black;
    padding: 20px 24px;
    border-radius: 10px;
    position: absolute;
    margin-right: auto;
    margin-left: auto;
    bottom: -40px;
    left: 0;
    right: 0;
    z-index: 1;

    ${media.phone} {
        padding: 24px 34px;
    }
    ${media.tablet} {
        bottom: -88px;
        padding: 30px 38px;
    }
    ${media.desktop} {
        max-width: 920px;
    }
`

export const TextWrap = styled.div`
    width: 100%;
    max-width: 430px;
    padding: 20px 0 26px 0;

    ${media.tablet} {
        padding: 0 0 0 20px;
    }
    ${media.desktop} {
        padding: 0 0 0 40px;
    }

    h3 {
        padding-bottom: 8px;
    }
`

export const Row = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    button {
        width: 100%;
        padding: 0;
        min-width: 190px;

        ${media.phone} {
            width: 190px;
        }
        ${media.tablet} {
            margin-left: auto;
        }
    }
    a {
        padding: 18px;
        color: inherit;
        display: block;
        text-decoration: none;
    }

    ${media.tablet} {
        align-items: center;
        flex-direction: row;
    }
`
