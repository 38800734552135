import { fromWei } from 'web3-utils'
import { useTranslation } from 'react-i18next'
import { Fade } from 'react-awesome-reveal'
import Img from '@static/images/available.webp'
import Plus from 'client/components/SVG/Plus'
import Minus from 'client/components/SVG/Minus'
import { Web3Context } from 'client/context/Web3'
import Countdown from 'client/components/Countdown'
import Button from 'client/components/shared/Button'
import Container from 'client/components/shared/Container'
import { Overflow } from 'client/components/shared/styled'
import TwitterIco from 'client/components/SVG/Twitter'
import DiscordIco from 'client/components/SVG/Discord'
import Stats from 'client/components/Stats'
import React, { useState, useContext } from 'react'
import { now, dateOfStartPublic, dateOfStartPrivate } from '@variables'
import {
    Row,
    Wrap,
    Amount,
    Value,
    Total,
    Banner,
    Buttons,
    Wrapper,
    GetMax,
    Title1,
    Title2,
    StatsWrap,
    MintButtonWrap
} from './styled'

const Mint = () => {
    const [t] = useTranslation()
    const [value, setValue] = useState(10)
    const {
        mint,
        tokenPrice,
        whitelist,
        totalSupply,
        selectedAccount,
        maxTokensPerTransaction
    } = useContext(Web3Context)

    const minus = () => {
        if (value > 0) {
            setValue(value - 1)
        }
    }

    const plus = () => {
        if (value < maxTokensPerTransaction) {
            setValue(value + 1)
        }
    }

    const submit = async () => {
        await mint(value)
    }

    const available = (10000 - (totalSupply || 0)).toLocaleString()
    const price = (
        parseFloat(fromWei(tokenPrice || '0', 'ether')) * value
    ).toFixed(2)

    const inWhiteList = whitelist.includes(selectedAccount.toLowerCase())

    const render = () => {
        if (
            now >= dateOfStartPublic ||
            (now >= dateOfStartPrivate && inWhiteList)
        ) {
            return (
                <Wrap>
                    <h2>{t('mint-title')}</h2>
                    <Amount>{t('amount')}</Amount>
                    <Row>
                        <Minus role="button" onClick={minus} />
                        <Value>{value}</Value>
                        <Plus role="button" onClick={plus} />
                        <GetMax
                            role="button"
                            onClick={() => setValue(maxTokensPerTransaction)}
                        >
                            {t('get-max')}
                        </GetMax>
                    </Row>
                    <Total>
                        {t('total')} {price} ETH
                    </Total>
                    <MintButtonWrap>
                        <Button color="white" onClick={submit}>
                            Mint
                        </Button>
                    </MintButtonWrap>
                    <StatsWrap>
                        <Stats color="white" />
                    </StatsWrap>
                    <Banner>
                        <div>
                            <h4>{t('available')}</h4>
                            <h3>{available} / 10,000</h3>
                        </div>
                        <img
                            src={Img}
                            loading="lazy"
                            alt="llamas"
                            width="353"
                            height="156"
                        />
                    </Banner>
                </Wrap>
            )
        }

        return (
            <Wrap>
                <Overflow>
                    <Fade
                        triggerOnce
                        direction="up"
                        duration={1500}
                        delay={100}
                    >
                        <Title1>
                            {inWhiteList
                                ? t('mint-white-title1')
                                : t('mint-public-title1')}
                        </Title1>
                    </Fade>
                </Overflow>
                <Overflow>
                    <Fade
                        triggerOnce
                        direction="up"
                        duration={1500}
                        delay={100}
                    >
                        <Title2>
                            {inWhiteList
                                ? t('mint-white-title2')
                                : t('mint-public-title2')}
                        </Title2>
                    </Fade>
                </Overflow>

                <Countdown
                    date={inWhiteList ? dateOfStartPrivate : dateOfStartPublic}
                />
                <Buttons>
                    <Button color="white">
                        <a
                            target="_blank"
                            href={t('discord-link')}
                            rel="noopener noreferrer"
                        >
                            <DiscordIco />
                            {t('join-discord')}
                        </a>
                    </Button>
                    <Button color="white">
                        <a
                            target="_blank"
                            href={t('twitter-link')}
                            rel="noopener noreferrer"
                        >
                            <TwitterIco />
                            {t('join-twitter')}
                        </a>
                    </Button>
                </Buttons>
                <StatsWrap>
                    <Stats color="white" />
                </StatsWrap>
            </Wrap>
        )
    }

    return (
        <Wrapper>
            <Container>{render()}</Container>
        </Wrapper>
    )
}

export default Mint
