import React from 'react'
import { Routes } from '@variables'
import AppImg from '@static/images/app.webp'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import MembershipImg from '@static/images/membership.webp'
import {
    Ul,
    Li,
    Row,
    Col,
    Wrap,
    Inner,
    Subtitle,
    MembershipWrap
} from './styled'
import { Overflow } from 'client/components/shared/styled'

const App = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Inner>
                <Overflow>
                    <Fade triggerOnce direction="up" duration={1500}>
                        <h2 id={Routes.APP_HASH}>{t('app-title')}</h2>
                    </Fade>
                </Overflow>
                <Fade triggerOnce duration={2000} delay={200}>
                    <Subtitle>{t('app-subtitle')}</Subtitle>
                </Fade>

                <Row>
                    <Col>
                        <img
                            src={AppImg}
                            alt={t('app-title')}
                            width="417"
                            height="704"
                            loading="lazy"
                        />
                    </Col>
                    <Col>
                        <Fade triggerOnce duration={2000}>
                            <p>{t('app-text')}</p>
                        </Fade>
                        <Ul>
                            <Fade
                                triggerOnce
                                duration={300}
                                direction="up"
                                cascade
                            >
                                {Array.from(Array(4)).map((i, idx) => (
                                    <Li key={idx}>{t(`app-item${idx}`)}</Li>
                                ))}
                            </Fade>
                        </Ul>
                        <MembershipWrap>
                            <img
                                src={MembershipImg}
                                alt={t('app-membership-title')}
                                loading="lazy"
                                width="83"
                                height="40"
                            />
                            <h4>{t('app-membership-title')}</h4>
                            <p>{t('app-membership-text')}</p>
                        </MembershipWrap>
                    </Col>
                </Row>
            </Inner>
        </Wrap>
    )
}

export default App
