import styled from 'styled-components'
import { colors, fontFamily, media, transition } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    max-width: 712px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 80px 16px 60px 16px;

    ${media.tablet} {
        padding: 110px 16px 80px 16px;
    }

    h2 {
        padding-bottom: 40px;

        ${media.tablet} {
            padding-bottom: 64px;
        }
    }
`

export const Tabs = styled.div`
    overflow: hidden;
`

export const TabContent = styled.div`
    max-height: 0;
`

export const TabLabel = styled.label`
    display: flex;
    cursor: pointer;
    justify-content: space-between;

    /* Icon */
    :hover {
        transition: ${transition.main};
    }
    &:after {
        content: '⌃';
        color: black;
        font-size: 32px;
        height: 24px;
        max-height: 24px;
        width: 26px;
        max-width: 26px;
        font-family: ${fontFamily.bold};
        transform: rotate(90deg);
    }
`

export const Tab = styled.div`
    width: 100%;
    overflow: hidden;
    margin-bottom: 40px;

    input {
        opacity: 0;
        z-index: -1;
        position: absolute;
    }

    input:checked {
        + ${TabLabel} {
            &::after {
                transform: rotate(180deg);
            }
        }
        ~ ${TabContent} {
            max-height: 100vh;
            padding-top: 24px;
        }
    }
`
