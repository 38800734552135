import styled from 'styled-components'

import BkgImg from '@static/images/herobkg.webp'
import { colors, fontFamily, media } from '@variables'

const getWidthAndHeightPx = (percentage: number, type: 'width' | 'height') => {
    if (type === 'width') {
        return `${8.96 * percentage}px`
    }
    return `${4.7 * percentage}px`
}

export const ButtonWrap = styled.div`
    button {
        width: 292px;
        height: 69px;
        margin: 30px auto 0 0;
    }
`

export const Wrapper = styled.div`
    width: 100%;
    position: relative;
    background-color: ${colors.black};

    padding-top: 140px;
    ${media.desktop} {
        padding-top: 200px;
    }

    :after {
        right: 0;
        content: '';
        margin: 0 auto;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url(${BkgImg});

        left: 0;
        bottom: 0;
        width: ${getWidthAndHeightPx(35, 'width')};
        height: ${getWidthAndHeightPx(35, 'height')};

        ${media.custom(400)} {
            width: ${getWidthAndHeightPx(45, 'width')};
            height: ${getWidthAndHeightPx(45, 'height')};
        }
        ${media.tablet} {
            left: 40%;
        }
        ${media.desktop} {
            left: 30%;
            width: ${getWidthAndHeightPx(80, 'width')};
            height: ${getWidthAndHeightPx(80, 'height')};
        }
        ${media.custom(1400)} {
            left: 40%;
            width: ${getWidthAndHeightPx(100, 'width')};
            height: ${getWidthAndHeightPx(100, 'height')};
        }
        ${media.custom(1800)} {
            left: 30%;
        }
    }
`

export const Row = styled.div`
    width: 100%;
    z-index: 2;
    display: flex;
    position: relative;
    flex-direction: column;

    padding-bottom: 180px;

    ${media.custom(400)} {
        padding-bottom: 220px;
    }
    ${media.tablet} {
        padding-bottom: 60px;
        flex-direction: row;
        justify-content: space-between;
    }

    ${media.desktop} {
        padding-bottom: 94px;
    }
`

export const Stage = styled.div`
    display: flex;
    padding: 20px 0 0 0;
    font-size: 12px;
    color: ${colors.yellow};
    font-family: ${fontFamily.bold};

    span:first-child {
        position: relative;

        :after {
            position: absolute;
            width: 50%;
            height: 1.8px;
            content: '';
            display: block;
            background-color: #ff0000;
            transform: rotate(14deg);
            top: 0;
            left: -60%;
            right: 0;
            bottom: 0;
            margin: auto;
        }
    }
    span:last-child {
        color: white;
        padding-left: 18px;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;

    text-align: right;
    color: ${colors.white};

    h1 {
        color: ${colors.yellow};
    }

    p {
        text-align: center;
        padding: 14px 12px 0 0;

        ${media.tablet} {
            text-align: left;
        }

        ${media.desktop} {
            max-width: 100%;
            margin-left: 0;
        }
    }

    ${media.tablet} {
        width: 50%;
        justify-content: flex-end;
    }

    img {
        height: auto;
        max-width: 230px;

        ${media.phone} {
            max-width: 287px;
        }
    }

    &:nth-child(1) {
        ${media.tablet} {
            max-width: 310px;
            margin-left: auto;

            div {
                width: 100%;
                text-align: left;
            }
        }
        ${media.desktop} {
            &:nth-child(1) {
                max-width: 450px;
            }
        }
        ${media.xl} {
            left: -65px;
            position: relative;
        }
    }
    &:nth-child(2) {
        ${media.tablet} {
            padding-left: 24px;
            align-items: flex-start;
        }
    }
`
