import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Container from 'client/components/shared/Container'

import Svg0 from 'client/components/SVG/Features/0'
import Svg1 from 'client/components/SVG/Features/1'
import Svg2 from 'client/components/SVG/Features/2'
import Svg3 from 'client/components/SVG/Features/3'
import Svg4 from 'client/components/SVG/Features/4'
import Svg5 from 'client/components/SVG/Features/5'
import { Wrap, Subtitle, Row, Col } from './styled'
import { Overflow } from 'client/components/shared/styled'

const Features = () => {
    const [t] = useTranslation()
    return (
        <Container>
            <Wrap>
                <Overflow>
                    <Fade triggerOnce direction="up" duration={1500}>
                        <h2>{t('features-title')}</h2>
                    </Fade>
                </Overflow>
                <Fade triggerOnce duration={2000} delay={200}>
                    <Subtitle>{t('features-subtitle')}</Subtitle>
                </Fade>
                <Row>
                    {Array.from(Array(6)).map((i, idx) => (
                        <Col key={idx}>
                            {getIcon(idx)}
                            <Overflow>
                                <Fade
                                    triggerOnce
                                    direction="up"
                                    duration={1500}
                                >
                                    <h3>{t(`features-item${idx}-title`)}</h3>
                                </Fade>
                            </Overflow>
                            <Fade triggerOnce duration={3000}>
                                <p>{t(`features-item${idx}-text`)}</p>
                            </Fade>
                        </Col>
                    ))}
                </Row>
            </Wrap>
        </Container>
    )
}

const getIcon = (idx: number) => {
    if (idx === 0) {
        return <Svg0 />
    }
    if (idx === 1) {
        return <Svg1 />
    }
    if (idx === 2) {
        return <Svg2 />
    }
    if (idx === 3) {
        return <Svg3 />
    }
    if (idx === 4) {
        return <Svg4 />
    }

    return <Svg5 />
}

export default Features
