import styled from 'styled-components'
import { colors, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-end;
        justify-content: center;
    }
`

export const Inner = styled.div`
    display: flex;
    width: 100%;
    max-width: 640px;
    justify-content: space-between;

    div {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    h2 {
        color: ${colors.white};

        @media (max-width: 479px) {
            font-size: 30px;
        }
    }
    h4 {
        color: ${colors.yellow};

        @media (max-width: 479px) {
            font-size: 14px;
        }
    }
`
