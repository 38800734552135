import React from 'react'
import { useTranslation } from 'react-i18next'

import SPCLogo from '@static/images/spc.webp'
import Button from 'client/components/shared/Button'
import { Row, Wrap, TextWrap } from './styled'

const SPCBanner = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Row>
                <img
                    src={SPCLogo}
                    alt="SPC"
                    width="102"
                    height="102"
                    loading="lazy"
                />
                <TextWrap>
                    <h3>Partnership with SPC</h3>
                    <p>
                        We are happy to announce collaboration with amazing
                        Space Punks Club.
                    </p>
                </TextWrap>
                <Button color="white">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://blog.spacepunks.club/space-punks-club-launchpad-beta-featuring-bullish-llamas/"
                    >
                        Learn more
                    </a>
                </Button>
            </Row>
        </Wrap>
    )
}

export default SPCBanner
