import React from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Img0 from '@static/images/team0.webp'
import Img1 from '@static/images/team1.webp'
import Img2 from '@static/images/team2.webp'
import Img3 from '@static/images/team3.webp'
import Img4 from '@static/images/team4.webp'
import Img5 from '@static/images/team5.webp'
import Img6 from '@static/images/team6.webp'
import Img7 from '@static/images/team7.webp'
import Social from 'client/components/shared/Social'
import { Overflow } from 'client/components/shared/styled'
import Container from 'client/components/shared/Container'
import { Col, ContactsCol, ContactsWrap, Row, Subtitle, Wrap } from './styled'
import { contractUrl } from '@variables'

const Team = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Container>
                <Overflow>
                    <Fade triggerOnce direction="up" duration={1500}>
                        <h2>{t('team-title')}</h2>
                    </Fade>
                </Overflow>
                <Fade triggerOnce duration={2000}>
                    <Subtitle>{t('team-subtitle')}</Subtitle>
                </Fade>
                <Row>
                    <Col>
                        <img
                            src={Img0}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item0-title')}
                        />
                        <h4>{t('team-item0-title')}</h4>
                        <p>{t('team-item0-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img1}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item1-title')}
                        />
                        <h4>{t('team-item1-title')}</h4>
                        <p>{t('team-item1-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img2}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item2-title')}
                        />
                        <h4>{t('team-item2-title')}</h4>
                        <p>{t('team-item2-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img3}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item3-title')}
                        />
                        <h4>{t('team-item3-title')}</h4>
                        <p>{t('team-item3-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img4}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item4-title')}
                        />
                        <h4>{t('team-item4-title')}</h4>
                        <p>{t('team-item4-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img5}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item5-title')}
                        />
                        <h4>{t('team-item5-title')}</h4>
                        <p>{t('team-item5-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img6}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item6-title')}
                        />
                        <h4>{t('team-item6-title')}</h4>
                        <p>{t('team-item6-text')}</p>
                    </Col>
                    <Col>
                        <img
                            src={Img7}
                            width="219"
                            height="214"
                            loading="lazy"
                            alt={t('team-item7-title')}
                        />
                        <h4>{t('team-item7-title')}</h4>
                        <p>{t('team-item7-text')}</p>
                    </Col>
                </Row>

                <ContactsWrap>
                    <ContactsCol>
                        <p>{t('contact-title')}</p>
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={`mailto:${t('contact-email')}`}
                        >
                            <h3>{t('contact-email')}</h3>
                        </a>
                    </ContactsCol>
                    <ContactsCol>
                        <p>{t('contact-links')}</p>
                        <a
                            target="_blank"
                            rel="noreferrer noopener"
                            href={contractUrl}
                        >
                            <h3>Ethscanner</h3>
                        </a>
                    </ContactsCol>
                    <Social color="white" />
                </ContactsWrap>
            </Container>
        </Wrap>
    )
}

export default Team
