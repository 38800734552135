import React from 'react'
import { Routes } from '@variables'
import { Parallax } from 'react-parallax'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import SPCBanner from './SPCBanner'
import Stats from 'client/components/Stats'
import DividerImg from '@static/images/divider.webp'
import Container from 'client/components/shared/Container'
import { Overflow } from 'client/components/shared/styled'
import { Wrapper, Wrap, Row, Col, Divider } from './styled'

const About = () => {
    const [t] = useTranslation()

    return (
        <Wrapper>
            <Container style={{ position: 'relative' }}>
                <Wrap>
                    <Stats color="black" />
                    <Overflow>
                        <Fade triggerOnce direction="up" duration={1500}>
                            <h2 id={Routes.ABOUT_HASH}>{t('club-title')}</h2>
                        </Fade>
                    </Overflow>
                    <Row>
                        <Col>
                            <Fade triggerOnce duration={2000} delay={200}>
                                <p>{t('club-text1')}</p>
                            </Fade>
                        </Col>
                        <Col>
                            <Fade triggerOnce duration={2000} delay={400}>
                                <p>{t('club-text2')}</p>
                            </Fade>
                        </Col>
                    </Row>
                </Wrap>
                <SPCBanner />
            </Container>
            <Parallax
                blur={0}
                strength={180}
                bgImageAlt="llama"
                bgImage={DividerImg}
            >
                <Divider />
            </Parallax>
        </Wrapper>
    )
}

export default About
