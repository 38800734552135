import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'

import { Web3Context } from 'client/context/Web3'
import Button from 'client/components/shared/Button'
import { Overflow } from 'client/components/shared/styled'
import { Wrapper, Row, Col, Stage, ButtonWrap } from './styled'

const Hero = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <Wrapper>
            <Row>
                <Col>
                    <Overflow style={{ padding: '0 5px 0 0' }}>
                        <Fade
                            triggerOnce
                            direction="up"
                            duration={1500}
                            delay={100}
                        >
                            <h1>{t('hero-title1')}</h1>
                        </Fade>
                    </Overflow>
                    <Overflow style={{ padding: '0 5px 0 0' }}>
                        <Fade
                            triggerOnce
                            direction="up"
                            duration={1500}
                            delay={200}
                        >
                            <h1>{t('hero-title2')}</h1>
                        </Fade>
                    </Overflow>
                    <Overflow style={{ padding: '0 5px 10px 0' }}>
                        <Fade
                            triggerOnce
                            direction="up"
                            duration={1500}
                            delay={300}
                        >
                            <h1 style={{ color: 'white' }}>
                                {t('hero-title3')}
                            </h1>
                        </Fade>
                    </Overflow>
                    <Overflow>
                        <Fade
                            triggerOnce
                            direction="up"
                            duration={1500}
                            delay={400}
                        >
                            <h4>{t('hero-subtitle')}</h4>
                        </Fade>
                    </Overflow>

                    <ButtonWrap>
                        <Button color="yellow" onClick={hahdleMintButton}>
                            {t('get-llama-now')}
                        </Button>
                    </ButtonWrap>

                    <Stage>
                        <span>
                            {t('hero-epoch-1')}: {t('hero-sold')}
                        </span>
                        <span>{t('hero-epoch-2')}</span>
                    </Stage>
                </Col>
                <Col></Col>
            </Row>
        </Wrapper>
    )
}

export default Hero
