import { Routes } from '@variables'
import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import AboutImg from '@static/images/about.webp'
import Button from 'client/components/shared/Button'
import { Web3Context } from 'client/context/Web3'
import Container from 'client/components/shared/Container'
import { Wrap, Subtitle, Row, Col, Ul, Li } from './styled'
import { Overflow } from 'client/components/shared/styled'

const Collection = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <Container>
            <Wrap>
                <Overflow>
                    <Fade triggerOnce direction="up" duration={1500}>
                        <h2 id={Routes.COLLECTION_HASH}>{t('about-title')}</h2>
                    </Fade>
                </Overflow>
                <Fade triggerOnce duration={2000} delay={200}>
                    <Subtitle>{t('about-subtitle')}</Subtitle>
                </Fade>

                <Row>
                    <Col>
                        <img
                            src={AboutImg}
                            alt={t('about-title')}
                            width="498"
                            height="498"
                        />
                    </Col>
                    <Col>
                        <Fade triggerOnce duration={2500}>
                            <p>{t('about-text')}</p>
                        </Fade>
                        <Ul>
                            <Fade
                                triggerOnce
                                duration={300}
                                direction="up"
                                cascade
                            >
                                {Array.from(Array(4)).map((i, idx) => (
                                    <Li key={idx}>{t(`about-item${idx}`)}</Li>
                                ))}
                            </Fade>
                        </Ul>
                        <Button color="black" onClick={hahdleMintButton}>
                            <span>{t('mint')}</span>
                        </Button>
                    </Col>
                </Row>
            </Wrap>
        </Container>
    )
}

export default Collection
