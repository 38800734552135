import styled, { css } from 'styled-components'

import { Props } from '.'
import { colors, media } from '@variables'

export const Item = styled.div`
    width: 100%;
    display: flex;
    max-width: 185px;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    :not(:last-of-type) {
        margin-bottom: 40px;
    }

    ${media.tablet} {
        :not(:last-of-type) {
            margin-bottom: 0;
        }
    }

    p {
        opacity: 0.5;
        padding-bottom: 4px;
    }

    svg {
        margin-right: 10px;
    }
`

export const CounterWrap = styled.div`
    display: flex;
    align-items: center;
`

export const Wrap = styled.div<Props>`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${media.tablet} {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
    }

    ${({ color }) =>
        color === 'black' &&
        css`
            ${Item} {
                :nth-child(1),
                :nth-child(2) {
                    path {
                        color: ${colors.black};
                        fill: ${colors.black};
                    }
                }
            }
        `}
`
