import React from 'react'

function Icon(props: TODO_ANY) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="60"
            height="60"
            fill="none"
            viewBox="0 0 60 60"
            {...props}
        >
            <circle cx="30" cy="30" r="22" fill="#fff"></circle>
            <circle cx="30" cy="30" r="30" fill="#fff" opacity="0.12"></circle>
            <path
                fill="#000"
                d="M20.048 30.996v-2.648c0-.4.126-.727.378-.98.267-.266.593-.4.979-.4h5.563v-5.54c0-.401.126-.728.378-.98.267-.267.601-.4 1.002-.4h2.626c.4 0 .726.133.979.4.267.252.4.579.4.98v5.54h5.83c.4 0 .727.134.98.4.266.253.4.58.4.98v2.648c0 .4-.134.727-.4.979-.253.252-.58.378-.98.378h-5.83v5.83c0 .4-.133.734-.4 1.002-.253.252-.579.378-.98.378h-2.625c-.4 0-.735-.126-1.002-.379-.252-.267-.378-.6-.378-1v-5.83h-5.563c-.4 0-.727-.127-.98-.38-.251-.251-.377-.578-.377-.978z"
            ></path>
        </svg>
    )
}

export default Icon
