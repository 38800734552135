import React from 'react'
import { Fade } from 'react-awesome-reveal'
import CountdownComponent, { CountdownRenderProps } from 'react-countdown'

import { Inner, Wrap } from './styled'
import Container from '../shared/Container'

const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed
}: CountdownRenderProps) => {
    if (!completed) {
        return (
            <Inner>
                <div>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={100}
                        direction="up"
                    >
                        <h2>{days}</h2>
                        <h4>Days</h4>
                    </Fade>
                </div>
                <div>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={200}
                        direction="up"
                    >
                        <h2>{hours}</h2>
                        <h4>Hours</h4>
                    </Fade>
                </div>
                <div>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={300}
                        direction="up"
                    >
                        <h2>{minutes}</h2>
                        <h4>Minutes</h4>
                    </Fade>
                </div>
                <div>
                    <Fade
                        triggerOnce
                        duration={800}
                        cascade
                        delay={400}
                        direction="up"
                    >
                        <h2>{seconds}</h2>
                        <h4>Seconds</h4>
                    </Fade>
                </div>
            </Inner>
        )
    }

    return <></>
}

type Props = {
    date: Date
}

const Countdown = ({ date }: Props) => (
    <Container>
        <Wrap>
            <CountdownComponent date={date} renderer={renderer} />
        </Wrap>
    </Container>
)

export default Countdown
