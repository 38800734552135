import React from 'react'
import { useTranslation } from 'react-i18next'

import Head from '../components/shared/Head'
import MintSection from '../sections/Mint'

const Mint = () => {
    const [t] = useTranslation()

    return (
        <>
            <Head />
            <MintSection />
        </>
    )
}

export default Mint
