import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type Props = {
    title?: string
    ogUrl?: string
    ogImgUrl?: string
    keywords?: string
    description?: string
}

const Head = ({
    title,
    ogUrl,
    keywords,
    description,
    ogImgUrl = `${process.env.RAZZLE_HOST}/og.webp`
}: Props) => {
    const [t] = useTranslation()

    const customKeywords = keywords ? keywords : t('seo-keywords')
    const customDescription = description ? description : t('seo-description')

    return (
        <Helmet>
            <title>
                {t('seo-title')}
                {title ? ` - ${title}` : ''}
            </title>
            <meta name="keywords" content={customKeywords} />
            <meta name="description" content={customDescription} />

            <meta property="og:type" content="website" />
            <meta property="og:image" content={ogImgUrl} />
            <meta property="og:description" content={customDescription} />
            <meta
                property="og:url"
                content={`${ogUrl ? ogUrl : process.env.RAZZLE_HOST}`}
            />
            <meta
                property="og:title"
                content={`${t('seo-title')}${title ? ` - ${title}` : ''}`}
            />
        </Helmet>
    )
}

export default Head
