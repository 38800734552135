import React, { useContext } from 'react'
import { Fade } from 'react-awesome-reveal'
import { useTranslation } from 'react-i18next'
import Img from '@static/images/hero-img.webp'
import Counter from 'client/components/Counter'
import { Web3Context } from 'client/context/Web3'
import Button from 'client/components/shared/Button'
import Container from 'client/components/shared/Container'
import { Overflow } from 'client/components/shared/styled'
import { Wrap, Subtitle, Col, ImgWrap, Item, Items, Row } from './styled'

const Usp = () => {
    const [t] = useTranslation()
    const { hahdleMintButton } = useContext(Web3Context)

    return (
        <Container>
            <Wrap>
                <Overflow>
                    <Fade triggerOnce direction="up" duration={1500}>
                        <h2>{t('usp-title')}</h2>
                    </Fade>
                </Overflow>
                <Fade triggerOnce duration={2000} delay={200}>
                    <Subtitle>{t('usp-subtitle')}</Subtitle>
                </Fade>

                <Row>
                    <Col>
                        <Items>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={17} />
                                </h3>
                                <p>{t('usp-body')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={19} />
                                </h3>
                                <p>{t('usp-clothes')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={3} />
                                </h3>
                                <p>{t('usp-ears')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={14} />
                                </h3>
                                <p>{t('usp-eyes')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={30} />
                                </h3>
                                <p>{t('usp-hat')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={8} />
                                </h3>
                                <p>{t('usp-eyes-item')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={6} />
                                </h3>
                                <p>{t('usp-horn')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={24} />
                                </h3>
                                <p>{t('usp-mouth')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={5} />
                                </h3>
                                <p>{t('usp-neck')}</p>
                            </Item>
                            <Item>
                                <h3>
                                    <Counter duration={3} head={0} tail={35} />
                                </h3>
                                <p>{t('usp-weapon')}</p>
                            </Item>
                        </Items>
                    </Col>
                    <Col>
                        <ImgWrap>
                            <img
                                src={Img}
                                alt={t('usp-title')}
                                loading="lazy"
                                width="287"
                                height="327"
                            />
                            <Button color="yellow" onClick={hahdleMintButton}>
                                <span>{t('usp-buton')}</span>
                            </Button>
                        </ImgWrap>
                    </Col>
                </Row>
            </Wrap>
        </Container>
    )
}

export default Usp
