/* eslint-disable @typescript-eslint/ban-ts-comment */
import isString from 'lodash/isString'
import { useCountUp } from 'react-countup'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

type Props = {
    head: number
    tail: number
    duration: number
    delay?: number
}

const Counter = ({ head, tail, duration, delay }: Props) => {
    const [lock, setLock] = useState(false)

    const { ref, inView } = useInView()

    const { countUp, start } = useCountUp({
        end: tail,
        start: head,
        delay: delay,
        duration: duration
    })

    useEffect(() => {
        if (inView && !lock) {
            start()
            setLock(true)
        }
    }, [inView])

    return (
        <span ref={ref}>
            {isString(countUp)
                ? parseInt(countUp, 10).toLocaleString()
                : countUp}
        </span>
    )
}

export default Counter
