import styled from 'styled-components'
import { colors, fontFamily, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 80px 0;

    h2 {
        text-align: center;
    }

    ${media.tablet} {
        padding: 140px 0;
    }
    ${media.desktop} {
        padding: 194px 0 180px 0;
    }
`

export const Subtitle = styled.p`
    padding: 20px 0 40px 0;
    text-align: center;

    ${media.tablet} {
        padding: 20px 0 60px 0;
    }
`

export const Row = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        flex-direction: row;
        justify-content: space-between;
    }
`

export const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;

    ${media.tablet} {
        width: 50%;

        &:nth-child(1) {
            align-items: flex-end;
            padding-right: 28px;
        }
        &:nth-child(2) {
            align-items: flex-start;
            padding-left: 42px;
        }
    }
`

export const Items = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    ${media.tablet} {
        max-width: 400px;
    }
`

export const Item = styled.div`
    display: flex;
    width: 50%;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    flex-direction: column;
`

export const ImgWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;

    ${media.tablet} {
        max-width: 334px;
    }

    img {
        width: 100%;
        height: auto;
        max-width: 287px;
    }

    button {
        position: absolute;
        bottom: -20px;
        width: 280px;
        height: 50px;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        left: -10px;
        right: 0;
        margin: auto;

        ${media.tablet} {
            right: initial;
            transform: none;
            margin: initial;
        }
    }
`
