import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'client/components/shared/Button'
import Container from 'client/components/shared/Container'
import DiscordLogoImg from '@static/images/discord-logo.webp'
import { Wrap, Inner } from './styled'

const Discord = () => {
    const [t] = useTranslation()

    return (
        <Wrap>
            <Container>
                <Inner>
                    <div>
                        <img
                            src={DiscordLogoImg}
                            alt="discord"
                            width="240"
                            height="66"
                            loading="lazy"
                        />
                        <h2>{t('community-title')}</h2>
                    </div>

                    <a
                        target="_blank"
                        href={t('discord-link')}
                        rel="noopener noreferrer"
                    >
                        <Button color="white">{t('join-discord')}</Button>
                    </a>
                </Inner>
            </Container>
        </Wrap>
    )
}

export default Discord
