import React from 'react'

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="80"
            height="80"
            fill="none"
            viewBox="0 0 80 80"
        >
            <path
                fill="#000"
                d="M27.498 15.942a.812.812 0 00-.317.017l-12.215 3.2a.8.8 0 00-.597.775v41.733a.8.8 0 001.003.775l12.192-3.194 12.192 3.194a.85.85 0 00.203.025c.026 0 .05-.01.075-.012.026.002.05.012.075.012a.845.845 0 00.203-.025l12.127-3.176 12.127 3.176a.807.807 0 00.692-.142.803.803 0 00.312-.634V19.933a.802.802 0 00-.598-.775l-12.214-3.2a.807.807 0 00-.317-.016.82.82 0 00-.32.017l-12.087 3.168-12.085-3.168a.795.795 0 00-.385.007c-.023-.006-.042-.02-.066-.024zm25.261 1.674l11.21 2.934v40.08l-11.2-2.935V17.66c0-.015-.008-.028-.01-.043zm-25.59.001v40.08L15.97 60.63v-40.08l11.199-2.934zm.8.003l11.2 2.935v40.078l-11.2-2.936V17.62zm24.001.035l-.001.004v40.074l-11.935 3.126-.065-.017v-40.11c.022 0 .043-.008.065-.01.026.002.05.012.075.012.068 0 .136-.01.203-.026l11.658-3.053zm4.95 2.325a.399.399 0 00-.326.298.4.4 0 00.289.486l5.486 1.4v4.295a.4.4 0 00.8.002v-4.606a.4.4 0 00-.3-.388l-5.79-1.478a.395.395 0 00-.159-.01zM41.17 22.06a.4.4 0 00-.4.4v7.2a.4.4 0 00.8 0v-7.2a.4.4 0 00-.4-.4zm21.6 6.4a.4.4 0 00-.4.4v2.4a.4.4 0 00.8 0v-2.4a.4.4 0 00-.4-.4zm-21.6 2.4a.4.4 0 00-.4.4v2.4a.4.4 0 00.8 0v-2.4a.4.4 0 00-.4-.4zm-24 16a.4.4 0 00-.4.4v7.2a.4.4 0 00.8 0v-7.2a.4.4 0 00-.4-.4zm0 8.8a.4.4 0 00-.4.4v2.4a.4.4 0 00.8 0v-2.4a.4.4 0 00-.4-.4z"
            ></path>
        </svg>
    )
}

export default Icon
