import { colors, media } from '@variables'
import styled from 'styled-components'

export const Wrap = styled.div`
    width: 100%;
    color: ${colors.white};
    padding: 90px 0 100px 0;
    background-color: #020202;
`

export const Inner = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    ${media.tablet} {
        text-align: left;
        flex-direction: row;
        justify-content: space-between;
    }

    img {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        margin-bottom: 12px;

        ${media.tablet} {
            margin: 0;
            max-width: 240px;
            margin-bottom: 0px;
        }
    }

    button {
        margin-top: 32px;
    }
`
