import styled from 'styled-components'

import { colors, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    padding: 80px 0;
    color: ${colors.white};
    background-color: ${colors.black};

    ${media.tablet} {
        padding: 130px 0 150px 0;
    }

    h2 {
        text-align: center;
    }
`

export const Subtitle = styled.p`
    text-align: center;
    padding: 12px 0 40px 0;

    ${media.tablet} {
        padding: 12px 0 60px 0;
    }
`

export const Row = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
`

export const Col = styled.div`
    display: flex;
    width: 100%;
    margin-bottom: 40px;
    align-items: center;
    flex-direction: column;

    ${media.custom(600)} {
        width: 50%;
    }
    ${media.tablet} {
        width: 33%;
        margin-bottom: 50px;
    }
    ${media.desktop} {
        width: 25%;
        margin-bottom: 70px;
    }

    h4 {
        padding: 20px 0 4px 0;
    }
`

export const ContactsWrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 220px;
    padding-top: 14px;
    flex-direction: column;

    ${media.tablet} {
        max-width: 680px;
        align-items: flex-end;
        flex-direction: row;
        justify-content: space-between;
    }
`

export const ContactsCol = styled.div`
    &:nth-child(1),
    &:nth-child(2) {
        padding-bottom: 30px;
    }

    ${media.tablet} {
        &:nth-child(1) {
            padding-right: 110px;
            padding-bottom: 0px;
        }
        &:nth-child(2) {
            padding-bottom: 0px;
        }
    }

    p {
        padding-bottom: 6px;
    }

    a {
        color: white;
        text-decoration: none;

        ${media.tablet} {
            :hover {
                text-decoration: underline;
            }
        }
    }
`
