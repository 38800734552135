import React from 'react'

import Counter from 'client/components/Counter'
import DiscordIco from 'client/components/SVG/Discord'
import TwitterIco from 'client/components/SVG/Twitter'
import LogoBlack from 'client/components/SVG/LogoBlack'
import LogoWhite from 'client/components/SVG/LogoWhite'
import { CounterWrap, Item, Wrap } from './styled'

export type Props = {
    color: 'white' | 'black'
}

const Stats = ({ color }: Props) => {
    return (
        <Wrap color={color}>
            <Item>
                <p>Community Member</p>
                <CounterWrap>
                    <DiscordIco />
                    <h3>
                        <Counter head={2340} tail={9000} duration={2} /> +
                    </h3>
                </CounterWrap>
            </Item>
            <Item>
                <p>Followers on twitter</p>
                <CounterWrap>
                    <TwitterIco />
                    <h3>
                        <Counter head={420} tail={6000} duration={2} /> +
                    </h3>
                </CounterWrap>
            </Item>
            <Item>
                <p>Llama Owners</p>
                <CounterWrap>
                    {color === 'black' ? <LogoBlack /> : <LogoWhite />}
                    <h3>
                        <Counter head={40} tail={1100} duration={2} /> +
                    </h3>
                </CounterWrap>
            </Item>
        </Wrap>
    )
}

export default Stats
