import styled from 'styled-components'
import { colors, media } from '@variables'

export const Wrap = styled.div`
    width: 100%;
    display: flex;
    margin: 0 auto;
    max-width: 720px;
    padding: 80px 16px;
    align-items: center;
    flex-direction: column;

    ${media.tablet} {
        padding: 144px 0;
    }

    h2 {
        text-align: center;
        padding-bottom: 40px;

        ${media.tablet} {
            padding-bottom: 80px;
        }
    }
`

export const Col = styled.div`
    padding-left: 20px;
    ${media.tablet} {
        padding-left: 66px;
    }
`

export const Row = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 52px;

    svg {
        width: 60px;
        height: 60px;
        min-width: 60px;
        min-height: 60px;
        position: relative;

        ${media.tablet} {
            width: 120px;
            height: 120px;
            min-width: 120px;
            min-height: 120px;
        }
    }
    h3 {
        padding-bottom: 14px;
    }
    p {
        font-size: 14px;
        line-height: 22px;

        ${media.tablet} {
            font-size: 18px;
            line-height: 32px;
        }
    }
`
