import styled from 'styled-components'
import { fontFamily, colors, media, transition } from '@variables'
import { AccessibleButton } from 'client/components/shared/styled'

export const Wrapper = styled.div`
    width: 100%;
    min-height: 800px;
    background-color: ${colors.black};
`

export const Title1 = styled.h4`
    color: ${colors.yellow};
    text-align: center;
`

export const Title2 = styled.h3`
    padding: 10px 0 30px 0;
    text-align: center;
`

export const StatsWrap = styled.div`
    width: 100%;
    max-width: 665px;
`

export const Buttons = styled.div`
    width: 100%;
    display: flex;
    max-width: 400px;
    flex-direction: column;
    padding: 30px 0 40px 0;

    ${media.phone} {
        flex-direction: row;
        padding: 40px 0 90px 0;
        justify-content: space-between;
    }

    button {
        padding: 0;
        font-size: 16px;
        box-shadow: none;
        margin: 0;

        svg {
            margin-right: 10px;
        }

        @media (max-width: 479px) {
            width: 100%;
            max-width: 200px;
            margin: 0 auto;

            :first-of-type {
                margin: 0 auto 24px auto;
            }
        }

        :first-of-type {
            path {
                fill: ${colors.black};
            }
        }
        :last-of-type {
            path {
                fill: #68dbff;
            }
        }
    }
    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 14px 16px;
        color: ${colors.black};
        text-decoration: none;
    }
`

export const Wrap = styled.div`
    width: 100%;
    color: ${colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 225px 0 134px 0;
`

export const MintButtonWrap = styled.div`
    margin: 24px 0 70px 0;
`

export const Row = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    svg {
        margin: 0 22px;
        cursor: pointer;
        will-change: transform;
        transition: ${transition.main};

        ${media.tablet} {
            :hover {
                transform: scale(1.1);
            }
        }
    }
`

export const GetMax = styled(AccessibleButton)`
    color: white;
    position: absolute;
    right: -90px;
    top: 50%;
    display: none;
    transform: translateY(-50%);
    margin: 0;
    text-decoration: underline;

    ${media.tablet} {
        display: block;
        :hover {
            text-decoration: none;
        }
    }
`

export const Amount = styled.p`
    color: ${colors.yellow};
    font-family: ${fontFamily.bold};
`

export const Value = styled.p`
    width: 125px;
    font-size: 87px;
    text-align: center;
    line-height: normal;
    letter-spacing: -0.05em;
    font-family: ${fontFamily.bold};
`

export const Total = styled.p`
    font-family: ${fontFamily.bold};
`

export const Banner = styled.div`
    width: 100%;
    height: 106px;
    max-width: 320px;
    border-radius: 20px;
    background-color: white;
    position: relative;
    margin-top: 95px;

    display: flex;
    align-items: center;

    ${media.tablet} {
        max-width: 712px;
    }

    div {
        padding: 0 24px;

        ${media.tablet} {
            padding: 0 0 0 60px;
        }
    }

    h4 {
        color: ${colors.green};
    }
    h3 {
        color: ${colors.black};
    }

    img {
        display: none;

        ${media.tablet} {
            position: absolute;
            bottom: -1px;
            right: 0;
            display: block;
        }
    }
`
